import { useState, useEffect } from 'react';
import { KeyboardAvoidingView, Platform, View, StyleSheet, Image } from 'react-native';
import { Portal, Button, Card, Text, Checkbox } from 'react-native-paper';
import { Snackbar as PaperSnackbar } from 'react-native-paper';
import * as Clipboard from 'expo-clipboard';
import { TextInput, HelperText } from 'react-native-paper';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../utils/fetchData';
import { SafeAreaView } from 'react-native-safe-area-context';
import LoadingSpinner from '../components/LoadingSpinner';
import BusinessLogo from '../components/BusinessLogo';
import Logo from '../components/Logo';

const GeneratedReview = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const profile = location.state.profile;
    const businessName = profile.name;
    const additional = profile.additional;
    const logo = profile.logo;
    const aboutBusiness = profile.about;
    var generatedReview = profile.review_text.trim();
    const tone = profile.tone;
    const businessType = profile.type;
    const selectedPhrases = profile.selectedPhrases; 
    const [text, onChangeText] = useState(generatedReview);
    const [loading, setLoading] = useState(false);
    const [agree, setAgree] = useState('unchecked');
    const [apiError, setApiError] = useState("");
    const [visible, setVisible] = useState(false);
    const onToggleSnackBar = () => setVisible(true);
    const redirect = () => {
      navigate('/review-page', {state:{profile: profile}});
    }
    const onDismissSnackBar = () => {
      setVisible(false);
      redirect();
    };
    const containerStyle = {backgroundColor: 'white', padding: 20};
    const [copyAndContinueLabel, setCopyAndContinueLabel] = useState("Copy and Continue");
    var regenerateCount = 0;
    useEffect(() => {
      document.title = props.title;
    }, [])
    if (loading) 
      return LoadingSpinner();
    else
      return (
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.container}>
          <SafeAreaView>
            <Card mode='contained' style={styles.card}>
            <BusinessLogo businessName={businessName} logo={logo} />
            <Logo businessName={businessName} logo={logo} />
              <Card.Content>
                <Text style={{paddingTop:10}} variant="titleMedium">Your AI-generated review for {businessName}</Text>
                <TextInput style={styles.textInput}
                  multiline={true} underlineColorAndroid='transparent'
                  readonly={false}
                  outlineStyle={{borderRadius: 10}}
                  outlineColor='#e4e5e7'
                  rows={10} mode='outlined'
                  onChangeText={onChangeText}
                  value={text}
                  /> 

              <Button mode='elevated' style={[styles.button]}
                title="Regenerate"
                contentStyle={{flexDirection: 'row-reverse'}}
                labelStyle={{ fontSize: 13 }}
                icon={({ size }) => (
                  <Image
                    source={require('../assets/icon.png')}
                    style={{ width: size, height: size, borderRadius: size / 2 }}
                  />
                )}
                onPress={
                  async () => {
                    regenerateCount += 0.1;
                    setLoading(true);
                    const {hasError, message} = await fetchData(
                      selectedPhrases, 
                      businessName, 
                      aboutBusiness, 
                      businessType,
                      tone, 
                    additional)
                    .then((data) => {return data})
                    .finally(() => setLoading(false));
                    if (hasError){
                      setApiError(message)
                    }
                    else{
                      generatedReview = message.trim();
                      onChangeText(generatedReview);
                    }
                  }
              }
              > Regenerate</Button>

                <HelperText type="error" visible={apiError != ""}>{apiError}</HelperText>
                <Text style={{marginTop: 20}} variant="titleSmall">By clicking “Copy and Continue”, you agree with the AI-generated review and confirm it reflects your opinion accurately.</Text>
              </Card.Content>
              <Card.Actions>
                <View style={{width:'100%'}}>
                <Button mode='elevated' style={styles.gradientButton}
                title="Copy and Continue"
                labelStyle={{ fontSize: 16 }}
                contentStyle={{flexDirection: 'row-reverse'}}
                icon={({ size }) => (
                  <Image
                    source={require('../assets/icon.png')}
                    style={{ width: size, height: size, borderRadius: size / 2 }}
                  />
                )}
                onPress={
                  async () => {
                    await Clipboard.setStringAsync(text, {inputFormat: Clipboard.StringFormat.PLAIN_TEXT});
                    profile.review_text = text;
                    onToggleSnackBar();
                    setCopyAndContinueLabel("Copied!");
                  }
              }
              >{copyAndContinueLabel}</Button>

              </View>
            </Card.Actions>
          </Card>
          <Portal>
        <PaperSnackbar
        visible={visible}
        title="Review Copied!"
        style={{backgroundColor: '#3c81f0'}}
        duration={3000}
        action={{
          label: 'Next',
          onPress: () => redirect(),
        }}

        onDismiss={onDismissSnackBar}>
       Almost there! Paste and edit your review on the Google review page, rate the business, and hit 'Post'.
      </PaperSnackbar>
        </Portal>
        </SafeAreaView>
      </KeyboardAvoidingView>);
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    view: {marginTop:10},
    button: {
      marginTop:10,
      marginBottom:10,
      alignSelf: 'flex-end',
      textAlignVertical: 'center',
      textAlign: 'center',
      height: 30,
      backgroundColor: 'rgb(119,199,254)',
      width: "50%"
    },
    textInput: {
      marginTop:10,
      backgroundColor: '#f0f2f4',
      width: "100%",
      padding: 0,
      fontSize: 15,
      textAlign: "left"
    },
    gradientButton: {
      height: 40,
      backgroundColor: '#3c81f0',
      textAlignVertical: 'center',
      fontSize: '16px',
      textAlign: 'center',
    },
    card : {
    },
  })

export default GeneratedReview;
