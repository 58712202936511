import AsyncStorage from '@react-native-async-storage/async-storage';

export const getSubscriptions = async (id, path) => {
    try {
        const value = await AsyncStorage.getItem(`revyou-${path}-${id}`);
        if(value !== null) {
            const jsonData = JSON.parse(value);
            if (jsonData.expires > Date.now()){ // check if data is not expired
                return {
                    hasError: false,
                    message: jsonData.data
                };
            }
            else{
                await AsyncStorage.removeItem(`revyou-${path}-${id}`); // remove expired data
            }
        }
    } catch (error) {
        console.log(error);
    }
    try{
        const user = `${process.env.WP_APPLICATION_USER}`;
        const passcode = `${process.env.WP_APPLICATION_PASSWORD}`;
        const token =  btoa(`${user}:${passcode}`);
        const url = `${process.env.WP_REST_API_BASE_URL}wp-json/revyou/v1/${path}/${id}`;
        const headers = {
            Authorization: 'Basic ' + token,
            accept: 'application/json',
        };

        const response = await fetch(url , {
            method: 'GET',
            headers: headers
        });
        if ( response.ok ){
            const jsonData = await response.json();
            await AsyncStorage.setItem(`revyou-${path}-${id}`, JSON.stringify( // save data to AsyncStorage
                {
                    data: jsonData,
                    expires: Date.now() + 43200000 // 12 hours
                }
            ));
            if (typeof jsonData === 'string' || jsonData instanceof String) {
                return {
                    hasError: true, 
                    message: jsonData
                };
            }else if (Object.keys(jsonData).length === 0 ){
                return {
                    hasError: true, 
                    message: 'The business you are trying to review either does not exist or is not subscribed to revyou.ai.'
                };
            } else if (Object.keys(jsonData).length === 6 ){
                return {
                    hasError: false, 
                    message: jsonData
                };
            } else{
                return {
                    hasError: true, 
                    message: 'Unknown error. Please try again.'
                };
            } 
        }

        if (response.status === 400){
            const jsonData = await response.json();
            return {
                hasError: true, 
                message: jsonData.statusText
            };
        }

        return {
            hasError: true, 
            message: `Server-side error occurred (${response.status})`
        };
    }
    catch (error) {
        return {
            hasError: true, 
            message: `Server-side error occurred (${error.message})`
        };
    }
};

    // return {
    //     hasError: false, 
    //     //message: jsonData
    //     message: {
    //         logo: 'https://localhost:19006/static/media/icon.80c876bc5f887b6c456f.png',
    //         name: 'RevYou',
    //         type: 'Restaurant',
    //         review_url: 'https:example.com/review',
    //         about: 'RevYou is a restaurant review app that helps you find the best restaurants in your area.'
    //     }
    // };
