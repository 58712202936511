import { SafeAreaView, StyleSheet } from 'react-native';
import { ActivityIndicator, Card, MD2Colors } from 'react-native-paper';

const LoadingSpinner = () =>  {
    return (
        <SafeAreaView style={styles.container}>
          <Card mode='contained'>
            <Card.Content>
            <ActivityIndicator size="large" color={MD2Colors.blueA700} animating={true} />
          </Card.Content>
          </Card>
        </SafeAreaView>
    );
  };
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
 });
  export default LoadingSpinner;
