import tones from "../data/tone.json"
import { useState } from 'react';
import { HelperText, Text } from 'react-native-paper';
import Slider from '@react-native-community/slider';
import { StyleSheet, View } from "react-native";

const ToneSlider = (props) => {
    const defaultIndex = tones.length-1;
    const [selected, setSelected] = useState(tones[defaultIndex]);
    props.onToneSelect(selected);
    return (
        <View>
            <Text style={{fontStyle: "italic", paddingTop: 10}}>{selected["use-case"]}</Text>
            <View style={styles.container}>
            <Text style={{padding:10}} variant="bodyLarge">Formal</Text>
         <Slider
            style={{width: 200, height: 40}}
            minimumValue={1}
            maximumValue={tones.length-1}
            minimumTrackTintColor="#ee8114"
            maximumTrackTintColor="#1A1C66" 
            thumbImage={require('../assets/slider-thumb.png')}
            thumbTintColor="white"
            onSlidingComplete={(value) => {
                props.onToneSelect(tones[value]);
            }}
            onValueChange={(value) => {
                setSelected(tones[value]);
            }}
            value={defaultIndex}
            step={1}
        /> 
        <Text style={{padding:10}} variant="bodyLarge">Creative</Text>
        </View>
      </View>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        width: 300,
      },
});
export default ToneSlider;

