
import { Card } from 'react-native-paper';
import { StyleSheet } from 'react-native';

const BusinessLogo = (props) => {
    const {businessName} = props;
    return (
        <Card.Title titleVariant='titleLarge' style={styles.title} titleStyle={styles.titleStyle} title={businessName }  />
    )
}


const styles = StyleSheet.create({
    titleStyle: {
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        hyphens: 'auto',
        textAlign: 'center',
        fontSize: 'clamp(1.25rem, 0.7386rem + 2.2727vw, 1.875rem)',
        maxWidth: '800px',

    },
    title: { 
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
});
export default BusinessLogo;
