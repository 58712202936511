import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { SafeAreaView, View, StyleSheet, Image } from 'react-native';
import { Button, Card } from 'react-native-paper';
import { Text } from 'react-native-paper';
import BusinessLogo from '../components/BusinessLogo';
import Logo from '../components/Logo';

export default function ReviewChoices(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const profile = location.state.profile;
  const businessName = profile.name;
  const logo = profile.logo;
  useEffect(() => {
    document.title = props.title;
  }, [])

  return (
    <View style={styles.container}>
      <SafeAreaView>
        <View style={[styles.view, { marginTop: '10%', marginBottom: '20%' }]}>
          <Image source={require('../assets/logo-with-text.png')} style={{ height: 60, resizeMode: 'center' }} />
        </View>
        <Card mode='contained'>
        <BusinessLogo businessName={businessName} logo={logo} />
          <Logo businessName={businessName} logo={logo} />
          <Card.Content>
            <Text variant="bodyMedium">Want to supercharge your review for {businessName} with AI?</Text>
          </Card.Content> 
          <Card.Actions>
            <View style={styles.view}>
              <Button mode="elevated"
                icon={({ size }) => (
                  <Image
                    source={require('../assets/icon.png')}
                    style={{ width: size, height: size, borderRadius: size / 2 }}
                  />
                )}
                style={styles.gradientButton}
                contentStyle={{ flexDirection: 'row-reverse' }}
                onPress={() => {
                  profile.review_generated = true;
                  navigate('/gen-ai-phrases', { state: { profile: profile } });
                }}
              >Yes, help me write a review</Button>
              <Button mode="elevated"
                contentStyle={{ flexDirection: 'row-reverse' }}
                style={styles.button}
                icon={({ size }) => (
                  <Image
                    source={require('../assets/icon.png')}
                    style={{ width: size, height: size, borderRadius: size / 2 }}
                  />
                )}
                onPress={() => {
                  profile.review_generated = false;
                  navigate('/review-page', { state: { profile: profile } });
                }}
              >No, I will write review myself</Button>
            </View>
            
          </Card.Actions>
        </Card>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  view: { marginTop: 10, width: '100%' },
  button: { marginTop: 10, backgroundColor: '#1A1C66', width: '100%' },
  gradientButton: {
    marginTop: 10,
    backgroundColor: 'rgb(119,199,254)',
    // backgroundColor: 'linear-gradient(271deg, rgba(119,199,254,1) 9%, rgba(80,148,233,1) 40%)'
  },
});
