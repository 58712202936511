import { Avatar } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
const Logo = (props) => {
    const {logo} = props;

    return (
        <View style={styles.view}>
        <Avatar.Image style={styles.shadow}  size={75} source={{uri: logo}} />
        </View>
    )
}

const styles = StyleSheet.create({
    view: {
        flex: 1,
        alignItems: 'center',
        paddingBottom: 3,
    },

    shadow: {
        boxShadow: '0px 2px 2px 2px #202020',
      },
});
export default Logo;
