import { registerRootComponent } from 'expo';
import { PaperProvider, configureFonts } from 'react-native-paper';
import { StrictMode } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { MD3LightTheme } from 'react-native-paper';
import App from './App';

const fontConfig = {
  customVariant: {
    fontWeight: 700,
    letterSpacing: 0.5,
    lineHeight: 22,
    fontSize: 20,
  },
  bodyLarge: {
    // letterSpacing: 0.5,
    // lineHeight: 22,
    fontWeight: 500,
    fontSize: 14,
  },
  bodyMedium:{ 
    fontWeight: "500",
    lineHeight: 18.4,
    fontSize: 50,
    letterSpacing: .25
  },
  titleMedium: {
    fontWeight: 600,
    fontSize: 14,
  },
  titleLarge: {
    fontWeight: 700,
    // letterSpacing: 0.15,
    lineHeight: 24,
  },
  headlineLarge: {
    fontWeight: 700,
    letterSpacing: 0.15,
    lineHeight: 18,
  },
  bodyMedium: {
    fontWeight: 700,
  },
  labelLarge: { // button label
    fontWeight: 600,
    lineHeight: 15.3,
    letterSpacing: .25
  },
}
const theme = {
    ...MD3LightTheme, // or MD3DarkTheme
    dark: false,
    version: 3,
    roundness: 2,
    colors: {
      ...MD3LightTheme.colors,
      primary: "white",  // icon and button text color
       surfaceVariant: "white", // card color for contained mode
       background: "#ffffff", // background color
      onPrimary: "blue",
      onPrimaryContainer: "blue",
      onSurface: "#102c42", // color on the card
      "elevation": {
        ...MD3LightTheme.colors.elevation,
        "level1": "white", // card color for elevated mode
        "level3": "white",
        "level2": "#112c47"
      },
    },
    fonts: configureFonts({config: fontConfig}),
  };

export default function Main() {
    return (
        <PaperProvider theme={theme}>
            <SafeAreaProvider style={{flex:1, justifyContent:'center', alignItems: 'center'}}>
                <StrictMode>
                    <App />
                </StrictMode>
            </SafeAreaProvider>
        </PaperProvider>
    );
}

  
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(Main);
