import { Component } from 'react'
import { View, StyleSheet } from 'react-native';
import QrReader from 'react-qr-scanner'
import { Text, Button, Card } from 'react-native-paper';
import parse from 'url-parse';

class QrCodeScanner extends Component {
  constructor(props){
    super(props);
    this.state = {
      delay: 1000,
      scanned: false,
      invalid: false,
      result: 'No result'
    }

    this.handleScan = this.handleScan.bind(this)
  }
  handleScan(data){
    if (this.state.scanned || !data) {
      return;
    }

    this.setState({scanned: true, result: data});
    try {
      const text = data['text'];
      const [path, id] = getCodeFromUrl(text);
      if (!Number.isNaN(Number(id))) {
        this.props.onValidData(id, path);
        this.setState({invalid: false});
      }
      else {
        codeData = JSON.parse(text); 
        if (codeData.hasOwnProperty('code')) {
          this.props.onValidData(codeData['code'], 'subscription');
          this.setState({invalid: false}); 
        }
        else{
          this.setState({invalid: true});
        } 
      }  
    }catch(e) {
      this.setState({invalid: true});
    }
  }

  handleError(err){
    console.error(err)
  }
  render(){
    return(
      <View style={styeles.container} >
        <QrReader 
          delay={this.state.delay}
          style={styeles.video}
          constraints={{
              audio: false,
              video: { facingMode: "environment" }
            }}
          onError={this.handleError}
          onScan={this.handleScan}     
          />
          <Card.Content>
            </Card.Content>
            { this.state.invalid && 
          <Text variant="bodyMedium" textColor="red">Invalid QR Code</Text> }
          <Card.Actions>
         
          { this.state.scanned && 
          <Button title="Scan Again" style={{ width: 300 }} mode='contained' onPress={()=>{this.setState({scanned: false, invalid: false})}} >
          Scan Again
          </Button> }
          </Card.Actions>
      </View>
    )
  }
}
const getCodeFromUrl=(url) => {
  const parsed = parse(url, true);
  const pathsegments = parsed['pathname'].split('/');

  if (pathsegments.length == 3 && pathsegments[1] === 'card') {
    return ['card',pathsegments[2]];
  }
  
  return ['subscription', pathsegments.length >= 2 ? pathsegments[1] : 0];
} 
const styeles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    margin: 10,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  video:{
    objectFit: 'fill',
    padding:1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderColor: 'rgba(0, 0, 0, 0)',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
  }
})

export default QrCodeScanner;
