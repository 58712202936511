import { useState, useEffect } from 'react';
import { SafeAreaView, ScrollView, View, StyleSheet } from 'react-native';
import { Chip } from 'react-native-paper';
import sentiments from "../data/phrases.json"

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
}

const ReviewSentiments = (props) => {
    const [data, setData] = useState([]);
    useEffect(() => {
      let inputData = shuffleArray(sentiments[props.businessType]);
      setData(inputData);
    }, []); 
  
    const setSelectedIndex = (id) => {
      data.map((item, index) => {
        if (index == id) {
          data[index].selected = !data[index].selected;
        } 
      });
      const selectedItems = data.filter((item) => item.selected).map((item) => item.name);
      props.onSelect(selectedItems);
      setData([...data]);
    };  

    const chips = data.map((item, index) => {
    return (<Chip
          textStyle={{  fontSize: 12, fontWeight: 'normal'}}
          style={[styles.chip, {
            backgroundColor: item.selected ? '#3c81f0' : '#e7eaed',
          }]}
          key={item.id}
          compact
          mode="flat"
          selectedColor= {item.selected ? 'white' : '#0e2c44'}
          showSelectedCheck={false}
          selected={!item.selected} onPress={() => setSelectedIndex(index)}>{item.name}
      </Chip>);
    });

    return (
      <SafeAreaView style={styles.container}>
        <ScrollView style={styles.wrapper}>
        <View style={styles.view}>
        {chips}
        </View>
        </ScrollView>
      </SafeAreaView>
    );
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      // padding: 2,
      // margin: 2,
    },
    wrapper: {
      // maxHeight:300,
    },
    view: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      // padding: 2,
      paddingHorizontal: 2,
     },
     chip: {
      margin: 1
     },
  });
  export default ReviewSentiments
