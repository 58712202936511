import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StyleSheet, Image, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Card, HelperText, Button } from 'react-native-paper';
import { getSubscriptions } from '../utils/getSubscriptions';
import QrCodeScanner from '../components/QrCodeScanner';

const Home = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [status, setStatus] = useState({message: '', showLoader: false, type: ''});
  const [visible, setVisible] = useState(false);
 
  useEffect(() => {
    document.title = props.title;
    processSubscription(id, props.path);
  }, []);

  const processSubscription = async (id, path) => {
    if (id) { 
      setStatus({type: 'info', showLoader: true,  message: 'Please wait while we fetch your business details...'});
      const userData = await getSubscriptions(id, path);
      if (userData.hasError){
        setStatus({type: 'error', showLoader: false, message: userData.message});
      }
      else {
        setStatus({message: '', showLoader: false, type: ''});
        const profile = userData.message;
        profile.review_generated = true;
        navigate('/gen-ai-phrases', { state: { profile: profile } });
      }
    }
  }
  const onValidData = async (id, path) => {
    await processSubscription(id, path);
  }
  return (
    <SafeAreaView style={visible? styles.containerCam : styles.container}>
   <View style={[styles.view, { marginTop: '10%', marginBottom: '20%' }]}>
      <Image source={require('../assets/logo-with-text.png')} style={{ height: 60, resizeMode: 'center' }} />
    </View>
    <Card mode='contained' style={styles.card}>
      <Card.Content>
        {status.showLoader && <ActivityIndicator animating={status.showLoader} color="#0e2c44" />}
        <HelperText variant='headlineLarge' style={styles.helperText}  type= {status.type} visible={status.message != '' }> {status.message} </HelperText>
      </Card.Content>
      <Card.Actions>
      {visible ? <QrCodeScanner onValidData= {onValidData} /> : 
        <Button style={styles.button} icon="camera" mode="elevated" onPress={() => {
        setStatus({message: '', showLoader: false, type: ''});
        setVisible(true);
       }}>
        Click to scan QR code
      </Button>}
      </Card.Actions>
      </Card>
      </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card : {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  containerCam: {
    flex: 1,
    justifyContent: 'center',
    width: '400px',
  },
  button: {
    marginTop:10, 
    backgroundColor: 'rgb(119,199,254)', 
    width: '100%'
  },
  text: {
    textAlign: 'right',
    width:'100%',
  },
  helperText: {
    fontWeight: 'bold',
    fontSize: 15,
    textAlign: 'center',
  },
  view: { marginTop: 10, width: '100%' },
})

export default Home;

