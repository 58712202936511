import { StyleSheet } from "react-native";
import { ActivityIndicator, Card, MD3Colors, Text } from 'react-native-paper';
import { useLocation } from 'react-router-dom';
import { sendTelemetry } from '../utils/sendTelemetry';
import * as Linking from 'expo-linking';
import { SafeAreaView } from "react-native-safe-area-context";

const  ReviewPage=({route, nav }) => {
    const location = useLocation();
    const profile = location.state.profile;
    const reviewUrl = profile.review_url;  
    sendTelemetry(profile).then((response) =>{
        console.log(response);
    }).finally(() => {
        Linking.openURL(reviewUrl);
    });
    return (
        <SafeAreaView style={styles.container}>
          <Card mode='contained'>
            <Card.Content>
              <Text variant="bodyLarge" >Please wait while we redirect you to the review page...</Text>
            <ActivityIndicator size="large" color={MD3Colors.primary10} animating={true} />
          </Card.Content>
          </Card>
        </SafeAreaView>
    );
  }
 const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
      },
}); 

export default ReviewPage;
