import prompts from '../data/prompts.json';

const removeDoubleQuotes = (str) => {
  if (str.startsWith('"') && str.endsWith('"')) {
    return str.slice(1, -1);
  }
  return str;
}
export const fetchData = async (reviewPhrases, businessName, aboutBusiness, businessType, tone, additional) => {

  const model = "gpt-4o";

  const promptIndex = Math.floor(Math.random() * prompts.length);
  const wordRange = '30-50'
  var prompt = prompts[promptIndex]
    .replace("[businessName]", businessName)
    .replace("[wordRange]", wordRange)
    .replace("[keywords]", "'"+ reviewPhrases.join("','") + "'").replace("[top_p]", tone.top_p).replace("[temperature]", tone.temperature)

    if (additional != ""){  
      prompt += "\n\n Also, make sure to incorporate the following additional feedback into the review with higher priority: \n\n'" + additional + "'";
    }
    + "\n\n Consider the following description of the business as background context: \n\n'" + aboutBusiness + "'";
    prompt += "Do not mention the business name.";
  const max_tokens = 1000;

  const body = {
    model: model,
    messages: [ {role: "system", content: "You are a review writer."},
    {role: 'user', content: prompt}],
    temperature: tone.temperature,
    top_p: tone.top_p,
    max_tokens: max_tokens
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.OPENAI_API_KEY}`,
  }

  try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });  

    if (response.status != 200){
      return {hasError:true, message: "OpenAI API call failed."}
    }
    const json = await response.json();
    if (json.choices.length == 0){
      return {hasError:true, message: "OpenAI API call failed."}
    }
    var message =  removeDoubleQuotes(json.choices[0].message.content.trim()); 
    return {hasError:false, message: message};
  }
  catch (error) {
    console.log('OpenAI API', error);
  }
};
