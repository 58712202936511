import { Image, Linking, Pressable, StyleSheet, View} from 'react-native';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import Home from './pages/Home';
import ReviewChoices from './pages/ReviewChoices';
import GenAiPhrases from './pages/GenAiPhrases';
import ReviewPage from './pages/ReviewPage';
import GeneratedReview from './pages/GeneratedReview';
import { Text } from 'react-native-paper';

export default function App() {  
  return (
    <View style={styles.container}> 
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home title="AI-Powered Review" path="/" />} />
        <Route path="/:id" element={<Home title="AI-Powered Review" path="subscription" />} />
        <Route path="/card/:id" element={<Home title="AI-Powered Review" path="card" />} />
        <Route path="/review-choices" element={<ReviewChoices title="Review Choices" />} />
        <Route path="/gen-ai-phrases" element={<GenAiPhrases title="Review Phrases" />} />
        <Route path="/review-page" element={<ReviewPage title="Review Submission" />} />
        <Route path="/generated-review" element={<GeneratedReview title="AI Generated Review" />} />
      </Routes>
    </BrowserRouter>
    <View style={{justifyContent: 'center', flexDirection: "row"}}>
    <Text style={{textAlign: 'left', fontWeight: 'normal', fontSize: '15px'}}>powered by </Text>
    <Pressable onPress={()=> Linking.openURL('https://revyou.ai/')} > 
      <Image style={{width: 80, height: 20, resizeMode: 'stretch'}} source={{uri: 'https://revyou.ai/wp-content/uploads/2024/02/new-logo-01.png'}} /> 
    </Pressable>
    </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: '500px',
    marginTop: '10px',
    marginBottom: '5px',
    marginLeft: '20px',
    marginRight: '20px',
    height: '100%',
    borderRadius: '10px',
    justifyContent: 'center',
  },
});