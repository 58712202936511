
export const sendTelemetry = async (profile) => {
    try{
        const user = `${process.env.WP_APPLICATION_USER}`;
        const passcode = `${process.env.WP_APPLICATION_PASSWORD}`;
        const token =  btoa(`${user}:${passcode}`);
        const url = `${process.env.WP_REST_API_BASE_URL}wp-json/revyou/v1/telemetry/${profile.subscription_id}`;
        const headers = {
            Authorization: 'Basic ' + token,
            accept: 'application/json',
        };
        profile.userAgent = window.navigator.userAgent;
        const telemetry = {
            user_agent: window.navigator.userAgent,
            subscription_id: profile.subscription_id,
            review_generated : profile.review_generated,
            review_text : profile.review_text,
            type: profile.type,
            additional: profile.additional,
            selected_phrases: profile.selectedPhrases,
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(telemetry)
        });
        console.log(await response.json());
    } 
    catch(e){
        console.log(e);
    }    
}
