import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { StyleSheet, SafeAreaView, Image, View  } from 'react-native';
import { Button, Card, HelperText, Text, TextInput } from 'react-native-paper';
import { useLocation } from 'react-router-dom';
import { fetchData } from "../utils/fetchData";
import LoadingSpinner from "../components/LoadingSpinner";
import ReviewSentiments from "../components/ReviewSentiments";
import ToneSlider from "../components/ToneSlider";
import Logo from "../components/Logo";
import BusinessLogo from "../components/BusinessLogo";
const GenAiPhrases = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [additional, setAdditional] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [apiError, setApiError] = useState("");
  const profile = location.state.profile;
  const businessName = profile.name;
  const businessType = profile.type;
  const aboutBusiness = profile.about;
  const logo = profile.logo;

  var selectedTone = {}
  useEffect(() => { document.title = props.title }, []);
  const onSelect = (data) => { setSelectedItems(data);};
  const onToneSelect = (data) => { selectedTone = data;};
  const onAdditionalChange = (data) => { setAdditional(data);};
  if (loading) 
    return LoadingSpinner();
  else
    return (
        <SafeAreaView style={styles.container}>
          <Card mode='contained' style= {styles.card}>
          <BusinessLogo businessName={businessName} logo={logo} />
            <Logo businessName={businessName} logo={logo} />
            <Card.Content>
              <Text style={{paddingTop:10}} variant="titleMedium">1. Choose the phrases that best describe your experience at {businessName}</Text>
              <ReviewSentiments onSelect={onSelect} businessType={businessType} />
              <HelperText type="error" visible={apiError!=""}>{apiError}</HelperText>
              <View>
                <Text style={{paddingTop:5}} variant="titleMedium">2. Choose the tone of your review</Text>
                <ToneSlider onToneSelect={onToneSelect} />
                <Text style={{paddingTop:10}} variant="titleMedium">3. Any other feedback?</Text>
                <TextInput type='flat' style={{borderColor:'black'}} variant="titleMedium" value={additional} placeholderTextColor={'#B2beb5'} placeholder="Example: Highly recommend!" onChangeText={onAdditionalChange} />
                <Text style={{paddingTop:10}} variant="titleMedium">4. Generate your review</Text>
              </View>
            </Card.Content>
          <Card.Actions> 
            <View style={{width:'100%'}}>
            <Button mode='elevated'
             contentStyle={{flexDirection: 'row-reverse'}}
             labelStyle={{ fontSize: 16 }}
            style={styles.gradientButton}
              title="Generate Review" icon={({ size }) => (
                <Image
                  source={require('../assets/icon.png')}
                  style={{ width: size, height: size, borderRadius: size / 2 }}
                />
              )}
              onPress={
                async () => {
                  if (selectedItems.length == 0){
                    setApiError("Please select at least one item.");
                    return;
                  }
                  setLoading(true);
                  const {hasError, message} = await fetchData(selectedItems, businessName, aboutBusiness, businessType, selectedTone, additional)
                  .then((data) => {return data})
                  .finally(() => setLoading(false));
                  if (hasError){
                    setApiError(message);
                  }
                  else{
                    profile.selectedPhrases = selectedItems;
                    profile.review_text = message;
                    profile.tone = selectedTone;
                    profile.additional = additional;
                    navigate('/generated-review', {state:{profile: profile}});
                  }
                }
            }
            >Generate Review</Button>              
            </View>
            </Card.Actions>
          </Card>
        </SafeAreaView>
    );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  card : {
  },
  gradientButton: {
    height: 40,
    backgroundColor: '#3c81f0'//'rgb(119,199,254)'
  },
});
export default GenAiPhrases;
